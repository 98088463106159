import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import { InfographicTransferDate, InfographicTransferShipment } from "../types";

/**
 * 시간 데이터를 생성하는 함수
 *
 * 얼마나 걸리는지 날짜를 가공하는 데이터는 remainedDays 값만 삽입
 */
function createTimeTaken(remainedDays: number): InfographicTransferShipment {
  return {
    shipName: "",
    voyageNumber: "",
    ATA: null,
    ETA: null,
    ATD: null,
    ETD: null,
    port: {
      code: "",
      id: 0,
      name: "",
      nameEN: "",
    },
    transportType: "sea",
    remainedDays,
  };
}

/**
 * 출발지-환적지-도착지 각 선적 일정을 YYYY-MM-DD 포맷으로 리턴
 *
 * 전부다 없는 경우 문자열 '' 리턴
 *
 * @example
 * 우선순위
 * 1. ATA
 * 2. ETA
 * 3. ATD
 * 4. ETD
 */
function getTransferShipmentDate({
  ATA,
  ETA,
  ATD,
  ETD,
}: InfographicTransferDate): string {
  return (
    toFormattedDate(ATA, "YYYY-MM-DD") ||
    toFormattedDate(ETA, "YYYY-MM-DD") ||
    toFormattedDate(ATD, "YYYY-MM-DD") ||
    toFormattedDate(ETD, "YYYY-MM-DD") ||
    ""
  );
}

export { createTimeTaken, getTransferShipmentDate };
